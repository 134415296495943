import { createContext, useContext } from "react";

export interface EnvContext {
  env: "production" | "development";
  /** The protocol + host of the webapp (e.g., https://annotate.dev)  */
  origin: string;
}

const EnvContext = createContext<EnvContext | null>(null);

export const EnvProvider = EnvContext.Provider;

export function useEnvContext() {
  const context = useContext(EnvContext);
  if (!context) {
    throw new Error("useEnvContext must be used within an EnvProvider");
  }

  return context;
}
